exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-js-event-loop-tsx": () => import("./../../../src/pages/blog/js-event-loop.tsx" /* webpackChunkName: "component---src-pages-blog-js-event-loop-tsx" */),
  "component---src-pages-blog-use-latest-eslint-tsx": () => import("./../../../src/pages/blog/use-latest-eslint.tsx" /* webpackChunkName: "component---src-pages-blog-use-latest-eslint-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-private-20231203-tsx": () => import("./../../../src/pages/private/20231203.tsx" /* webpackChunkName: "component---src-pages-private-20231203-tsx" */),
  "component---src-pages-private-20231216-tsx": () => import("./../../../src/pages/private/20231216.tsx" /* webpackChunkName: "component---src-pages-private-20231216-tsx" */),
  "component---src-pages-private-ping-zi-tsx": () => import("./../../../src/pages/private/ping-zi.tsx" /* webpackChunkName: "component---src-pages-private-ping-zi-tsx" */)
}

